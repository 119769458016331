import React, {useContext, useState, useTransition} from "react";
import {Link} from "react-router-dom";
import _ from 'lodash'
import SearchField from "../common/form/searchField";
import useTerminals from "../../hooks/useTerminals";
import PropTypes from "prop-types";
import Select from "react-select";
import {ThemeContext} from "../../hooks/themeProvider";
import ModalUpdatePrice from "../modalUpdatePrice";
import ModalDeleteOrderTerm from "../modalDeleteOrderTerm";
import TableHeader from "./table/tableHeader";
import TableBody from "./table/tableBody";
import TableFooter from "./table/tableFooter";
import CheckBoxTable from "./table/test";

const TerminalsTable = ({terminals, extraWorks}) => {
    const [orderId, setOrderId] = useState('')
    const [modalUpdatePrice, setModalUpdatePrice] = useState(false)

    const [isPending, startTransition] = useTransition()

    const [btnOn, setBtnOn] = useState(true)
    const [modalDeleteOrderTerm, setModalDeleteOrderTerm] = useState(false)

    const [theme, setTheme] = useContext(ThemeContext)

    const changeTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light')
    }

    const {year, month, currentMonth, currentYear, dispatch, setting, history} = useTerminals()
    const selectMonth = localStorage.getItem('month')
    const selectYear = localStorage.getItem('year')

    //--- Фильтрация по месяцу и поисковая строка---
    const filterCurrentYear = year.filter(y => Number(y.label) === currentYear)
    const [selectedMonth, setSelectedMonth] = useState(selectMonth === null ? month[currentMonth] : JSON.parse(selectMonth))
    const [selectedYear, setSelectedYear] = useState(selectYear === null ? filterCurrentYear[0] : JSON.parse(selectYear))
    const [searchQuery, setSearchQuery] = useState('')
    const [searchQueryValue, setSearchQueryValue] = useState('')

   

    const handleMonthSelect = item => {
        if (searchQuery !== '') setSearchQuery('')
        setSelectedMonth(item)
        localStorage.setItem('month', JSON.stringify(item))
    }
    const handleYearSelected = item => {
        if (searchQuery !== '') setSearchQuery('')
        setSelectedMonth(null)
        setSelectedYear(item)
        localStorage.setItem('year', JSON.stringify(item))
    }

    const handleSearchQuery = ({target}) => {
        setSearchQuery(target.value)
        startTransition(() => {
            setSearchQueryValue(target.value)
        })
    }

    function filterTerminals(sortedDataTerminals, selectedYear, selectedMonth, searchQuery) {
        let filteredTerminals = sortedDataTerminals;

        if (selectedYear) {
            filteredTerminals = filteredTerminals.filter(
                terminal => terminal.year.value === selectedYear.value,
            );
            if (!selectedMonth) {
                localStorage.setItem('month', JSON.stringify(''))
            }
        }

        if (selectedMonth) {
            filteredTerminals = filteredTerminals.filter(
                terminal => terminal.month.value === selectedMonth.value
            );
        }

        if (searchQueryValue) {
            filteredTerminals = sortedDataTerminals.filter(
                terminal => String(terminal.number).includes(searchQueryValue)
            );
        }

        return filteredTerminals;
    }

    const sortedDataTerminals = _.orderBy(terminals, ['created_at'], ['asc'])

    const filteredExtraWorks = filterTerminals(extraWorks, selectedYear, selectedMonth)
    const filteredTerminal = filterTerminals(sortedDataTerminals, selectedYear, selectedMonth, searchQuery)
    // ----------------------------------------------------------

    const handleClickTerminalInfo = (id) => {
        // history.push(history.location.pathname + `/${id}/editTerminal`)
        history.push(history.location.pathname + `${id}/terminalInfo`)
    }

    const checkBtn = () => {
        setBtnOn((prevState) => !prevState)
    }

    return (
        <div className='container-lg flex-column mt-2'>
            <div className='app_table-container'>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex'>
                       <Link
                            to={'/infoPage'}
                            className='d-flex justify-content-between btn btn-light mb-2 me-2'
                        >
                            <div>
                                <i className="bi bi-info-square"></i>
                            </div>
                        </Link>
                       <Link
                           to={'/Statistics'}
                           className='d-flex justify-content-between btn btn-light mb-2'
                       >
                           <div>
                               <i className="bi bi-bar-chart-line"></i>
                           </div>
                       </Link>
                    </div>
                    <div className='d-flex'>
                       <i className='bi bi-brightness-high-fill me-2 '></i>
                       <div className='form-switch'>
                           <input
                               className='form-check-input'
                               type='checkbox'
                               role='switch'
                               id='flexSwitchCheckDefault'
                               onChange={() => changeTheme()}
                               checked={theme !== 'light'}
                           />
                           <i className='bi bi-moon-fill ms-2 '></i>
                       </div>
                    </div>
                    <Link
                       to={'/createSetting'}
                       className='d-flex justify-content-between btn btn-light mb-2'
                    >
                       <div>
                           <i className="bi bi-gear"></i>
                       </div>
                    </Link>
                </div>
                <div className='d-flex pt-1 mb-2'>
                    <div style={{width: 250, marginRight: 15}}>
                        <Select
                            classNamePrefix='custom-select'
                            isSearchable={false}
                            closeMenuOnSelect={true}
                            defaultValue={selectedYear}
                            options={year}
                            onChange={handleYearSelected}
                        />
                    </div>
                    <div style={{width: 250, marginRight: 15}}>
                        {selectedMonth === null ? (
                            <div>
                                <Select
                                    classNamePrefix='custom-select'
                                    isSearchable={false}
                                    closeMenuOnSelect={true}
                                    defaultValue={null}
                                    options={month}
                                    onChange={handleMonthSelect}
                                />
                            </div>
                        ) : (
                            <Select
                                classNamePrefix='custom-select'
                                isSearchable={false}
                                closeMenuOnSelect={true}
                                defaultValue={selectedMonth}
                                options={month}
                                onChange={handleMonthSelect}
                            />
                        )}

                    </div>
                    <SearchField
                        name='search'
                        onChange={handleSearchQuery}
                        value={searchQuery}
                    />
                </div>
                <div className='form-check'>
                    <input
                        onClick={checkBtn}
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                    />
                    <label className='form-check-label' htmlFor='flexCheckDefault'>
                        Удаление заказа
                    </label>
                </div>
            </div>
            <div className='app_table-container mt-2'>
                {isPending ? (
                    <h3 className='text-center'>Загрузка...</h3>
                ) : (
                    <table className='table table-sm table align-middle'>
                        <TableHeader
                            searchQuery={searchQuery}
                            selectedMonth={selectedMonth}
                            filteredTerminal={filteredTerminal}
                            setModalUpdatePrice={setModalUpdatePrice}
                            handleMonthSelect={handleMonthSelect}
                        />
                        <TableBody
                            filteredTerminal={filteredTerminal}
                            searchQuery={searchQuery}
                            setOrderId={setOrderId}
                            btnOn={btnOn}
                            setModalDeleteOrderTerm={setModalDeleteOrderTerm}
                        />
                        {!searchQuery && (
                            <TableFooter
                                selectMonth={selectMonth}
                                selectedMonth={selectedMonth}
                                filteredExtraWorks={filteredExtraWorks}
                                filteredTerminal={filteredTerminal}
                            />
                        )}
                    </table>
                )}
                {/*<div>*/}
                {/*    <CheckBoxTable/>*/}
                {/*</div>*/}
            </div>
            {modalUpdatePrice && <ModalUpdatePrice
                hide={setModalUpdatePrice}
                terminals={filteredTerminal}
                dispatch={dispatch}
                setting={setting}
                month={selectedMonth}
                year={selectedYear}
            />}
            {modalDeleteOrderTerm && <ModalDeleteOrderTerm
                hide={setModalDeleteOrderTerm}
                filteredTerminal={filteredTerminal}
                dispatch={dispatch}
                orderId={orderId}
            />}
        </div>
    )
}
TerminalsTable.propTypes = {
    terminals: PropTypes.array,
    extraWorks: PropTypes.array
}
export default TerminalsTable
